import moment from "moment";

export function dateRange(startDate, endDate) {
  var start = moment(startDate).format("YYYY-MM-DD").split("-");
  var end = moment(endDate).format("YYYY-MM-DD").split("-");
  var startYear = parseInt(start[0]);
  var endYear = parseInt(end[0]);
  var dates = [];

  for (var i = startYear; i <= endYear; i++) {
    var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
    var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var month = j + 1;
      var displayMonth = month < 10 ? "0" + month : month;
      dates.push([i, displayMonth, "01"].join("-"));
    }
  }
  return dates;
}
export function getRangeMonthsSY(activeSchoolarYear, schoolarYears) {
  let start, end;
  schoolarYears.forEach((elem) => {
    if (elem._id == activeSchoolarYear) {
      end = elem.end;
      start = elem.start;
    }
  });
  const range = dateRange(
    moment(start).format("YYYY-MM-DD"),
    moment(end).format("YYYY-MM-DD")
  );
  return range;
}
export function getStatus(entry, insc) {
  if (!entry.inscription && insc > 0) return "impayed";
  else {
    if (entry.once == false && entry.months) {
      for (const [key, value] of Object.entries(entry.months)) {
        if (!value) return "impayed";
      }
    }
    return "payed";
  }
}
export function formatFeeName(text) {
  if (moment(text, moment.ISO_8601, true).isValid()) {
    const month = moment(text).format("MMMM");
    return month.charAt(0).toUpperCase() + month.slice(1);
  } else if (!isNaN(text)) {
    return `Tranche ${text}`;
  } else {
    return text; // or handle other cases if needed
  }
}
