import JsPDF from "jspdf";
import { formatFeeName } from "@/utilities/settings";
import writtenNumber from "written-number";

const pdfHeader = (settings, nomClient, title, subTitle = null) => {
  let arrayOfImages = settings.image;
  let imgData = new Image();

  for (let i = 0; i < arrayOfImages.length; i++) {
    if (settings.activeBuilding === arrayOfImages[i].db) {
      imgData.src = arrayOfImages[i].logo;
      break; // Stop looping once the correct image is found
    }
  }

  const doc = new JsPDF();
  doc.setFont("Amiri");

  const date = new Date();
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  // Center the title
  const pageWidth = doc.internal.pageSize.getWidth();
  const titleWidth = doc.getTextWidth(title);
  const titleX = (pageWidth - titleWidth) / 2;

  doc.setFontSize(15);
  doc.text(titleX, 22, title); // Use calculated x-coordinate for centering
  if (subTitle) {
    // Calculate the x-coordinate for centering the subtitle
    let subTitleWidth = doc.getTextWidth(subTitle);
    let subTitleX = (pageWidth - subTitleWidth) / 2;

    // Add the subtitle to the document
    doc.text(subTitleX, 29, subTitle);
  }
  if (nomClient) doc.text(15, 35, `Nom et Prénom: ${nomClient}`);
  doc.setFontSize(10);
  if (imgData.src) {
    doc.addImage(imgData, "JPEG", 15, 5, 20, 20);
  }
  doc.setFontSize(8);
  doc.text(170, 15, date.toLocaleDateString("fr-FR", options));

  return doc;
};
const printTransaction = (record, settings) => {
  const title =
    record.typeFR == "F"
      ? "Facture N. " + record.serial
      : "Reçue N. " + record.serial;
  const doc = pdfHeader(settings, record.nomClient, title);

  var arr = [];
  const array = [];

  let onTransReduction = record.onTransReduction
    ? record.onTransReduction / 100
    : 0;

  let repReduction = record.payment.repReduction;
  let baseReduction = false;
  if (repReduction) {
    for (const month in record.info.months) {
      if (Number(repReduction[month])) {
        baseReduction = true;
        break;
      }
    }
    if (record.onInscription && repReduction.inscription) baseReduction = true;
  }

  if (record.fees) {
    Object.keys(record.fees).forEach((k) => {
      arr.push(record.student.lastName + " " + record.student.firstName);

      let feeName = formatFeeName(k);

      arr.push(feeName);
      arr.push(record.typeReglement);

      const fee = record.fees[k];

      if (baseReduction) {
        arr.push(
          (repReduction[k] ? Number(repReduction[k]).toFixed(2) : 0) + "%"
        );
      }
      let montantCol = record.typeFR == "F" ? fee - fee * record.tva : fee;
      arr.push(montantCol.toFixed(2));

      array.push(arr);
      arr = [];
    });
  }
  let rowData = [
    record.montantHT,
    record.montantTTC - record.montantHT - record.fiscal,
    record.fiscal,
    record.montantTTC,
  ];

  //affichier reduction si existe
  if (onTransReduction) {
    if (record.typeFR == "R") {
      arr.push({
        colSpan: baseReduction ? 4 : 3,
        content: "Total sans réduction",
        styles: { valign: "middle", halign: "center", fontStyle: "bold" },
      });
      arr.push({
        content: (record.montantTTC / (1 - onTransReduction)).toFixed(2),
        styles: { valign: "middle", halign: "center", fontStyle: "bold" },
      });
      array.push(arr);
      arr = [];
    }
    arr.push({
      colSpan: baseReduction ? 4 : 3,
      content: "Réduction",
      styles: { valign: "middle", halign: "center", fontStyle: "bold" },
    });
    arr.push({
      content: (onTransReduction * 100).toFixed(2) + "%",
      styles: { valign: "middle", halign: "center", fontStyle: "bold" },
    });
    array.push(arr);
    arr = [];
  }
  let rowDataText = ["Total HTVA", "TVA", "Timbre", "Total TTC"];
  for (let i = 0; i < 4; i++) {
    if (record.typeFR == "R") {
      i = 3;
      rowDataText[i] = "Total";
    }
    arr.push({
      colSpan: baseReduction ? 4 : 3,
      content: rowDataText[i],
      styles: { valign: "middle", halign: "center", fontStyle: "bold" },
    });

    arr.push({
      content: parseFloat(rowData[i]).toFixed(2),
      styles: { valign: "middle", halign: "center", fontStyle: "bold" },
    });
    array.push(arr);
    arr = [];
  }

  doc.autoTable({
    styles: {
      lineWidth: 0.2,
      valign: "middle",
      halign: "center",
      fontStyle: "bold",
      font: "Amiri",
    },
    startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 15 : 38,
    head: baseReduction
      ? [["Enfant", "Frais", "Désignation", "réduction de base", "Montant"]]
      : [["Enfant", "Frais", "Désignation", "Montant"]],
    body: array,
    foot: [
      [
        {
          colSpan: baseReduction ? 5 : 4,
          content:
            "Le montant total est " +
            writtenNumber(record.montantTTC, { lang: "fr" }) +
            " DT",
          styles: { halign: "center" },
        },
      ],
    ],
  });
  doc.save(title + ".pdf");
};
export { pdfHeader, printTransaction };
